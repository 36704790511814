@import "_inputrange";

.widget-custom-form {
  & > form > * {
    margin-bottom: 1em;
  }

  //.widget-grid {
  //  align-items: flex-end;
  //}

  .custom-control-label {
    line-height: 24px;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #0074d966;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: inherit;
  }

  .was-validated .custom-select {
    background-color: #fff;
    border-style: solid;
    border-width: 1px;
  }
  label {
    display: block;
  }
  .form-group {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .preview-form, .preview-response {
    border-left: 2px dashed;
    border-right: 2px dashed;
    border-bottom: 2px dashed;
    padding: 5px;
    border-color: #dcdbd8;

    .widget-grid > .widget-inner > * {
      border: 1px dashed #dcdbd8;
      padding:5px;
    }

    &:first-child {
      border-top: 2px dashed #dcdbd8;
    }
  }
  .shopbuilder-only {
    color: #dcdbd8;
  }
  .form-control[type="range"] {
    border: 0 !important;
    background-color: transparent !important;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  output {
    display: block;
  }
}
